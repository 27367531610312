// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fpd-bulk-order-form .fpd-bo-bulk-order-header {
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-right: 15px;
  height: 40px;
  align-items: center;
  max-width: max(30%, 400px);
  min-width: 100px;
}
fpd-bulk-order-form .fpd-bo-bulk-order-header select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  flex-grow: 1;
  background: 0 0;
  z-index: 10;
  outline: 0;
  cursor: pointer;
  margin: 0;
  padding-left: 10px !important;
  padding-right: 15px !important;
  display: block !important;
  max-width: 100%;
  box-shadow: none;
  margin-left: -10px !important;
}
fpd-bulk-order-form .fpd-bo-bulk-order-header .fpd-bo-svg {
  position: relative;
  right: 10px;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row {
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row.first-row {
  border-top: 1px solid #222;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-name-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-name-container .fpd-bo-variant-image {
  margin-right: 10px;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-name-container .fpd-bo-variant-image img {
  width: 50px;
  height: 50px;
  vertical-align: bottom;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-name-container .fpd-bo-variant-info {
  display: flex;
  flex-direction: column;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-name-container .fpd-bo-variant-price {
  font-weight: bolder;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-quantity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-quantity-container input {
  width: 60px;
  height: 30px;
  margin: 5px;
  text-align: center;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  border-style: solid;
  border-width: 1px;
}
fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-quantity-container .fpd-bo-variant-increase, fpd-bulk-order-form .fpd-bo-bulk-order-row .fpd-bo-quantity-container .fpd-bo-variant-decrease {
  cursor: pointer;
  width: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
fpd-bulk-order-form .fpd-bo-show-more .fpd-bo-show-more-button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  font-weight: bolder;
}
fpd-bulk-order-form #fpd-bo-bulk-order-footer {
  padding-top: 20px;
  border-top: 1px solid #222;
  font-weight: bolder;
}
fpd-bulk-order-form #fpd-bo-bulk-order-footer div {
  padding-bottom: 10px;
}
fpd-bulk-order-form #fpd-bo-bulk-order-atc {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/fpd-bulk-order-form.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,0BAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,6BAAA;EACA,8BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;AAAN;AAEI;EACE,kBAAA;EACA,WAAA;AAAN;AAIE;EAKE,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AANJ;AAJI;EACE,0BAAA;AAMN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAHN;AAKM;EACE,kBAAA;AAHR;AAIQ;EACE,WAAA;EACA,YAAA;EACA,sBAAA;AAFV;AAMM;EACE,aAAA;EACA,sBAAA;AAJR;AAOM;EACE,mBAAA;AALR;AAQI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AANN;AAQM;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AANR;AAQM;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,yCAAA;AANR;AAYI;EACE,eAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AAVN;AAaE;EACE,iBAAA;EACA,0BAAA;EACA,mBAAA;AAXJ;AAeI;EACE,oBAAA;AAbN;AAiBE;EACE,WAAA;AAfJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
